import { makeStyles } from '@material-ui/core/styles';
import axios from 'axios';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
  menu: {
    zIndex: '333333',
  },
  list: {
    width: 250,
  },
  fullList: {
    width: 'auto',
  },
}));

async function getTopicTitles() {
  const {data} = await axios.get(`${process.env.REACT_APP_API_ENDPOINT}/topic/get-topic-titles`);
  return data;
}

async function getNotIds() {
  const {data} = await axios.get(`${process.env.REACT_APP_API_ENDPOINT}/subscription/get-new-notifications-ids`);
  return data;
}

export { useStyles, getTopicTitles, getNotIds }

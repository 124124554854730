import React, { useEffect, useState } from "react";
import { Link, BrowserRouter as Router } from "react-router-dom";
import { Box, Typography, Button, useMediaQuery } from "@material-ui/core";
import { donateSvg } from "./components/footer/svg";
import Loader from "react-loader-spinner";
import { HDate } from "@hebcal/core";

import "./App.css";
import Header from "./components/header/Header";
import Footer from "./components/footer/Footer";
import RouteConfig from "./navigation/RouterConfig";
import { isAuthenticated } from "./components/login/loginService";
import { englishContext, alertContext, engDateDesc } from "./contexts";
import axios from "axios";

async function getAd() {
  return axios.get(`${process.env.REACT_APP_API_ENDPOINT}/misc/get-ad`);
}

function App() {
  const [auth, setAuth] = useState(false);
  const [spinner, setSpinner] = useState(false);
  const mobile = useMediaQuery("(max-width: 600px)");
  const [isEnglish, setIsEnglish] = useState(false);
  const [isViewd, setIsViewd] = useState(true);
  const [desc, setDesc] = useState(true);
  const [successModal, setSuccessModal] = useState(false);
  const [advertisement, setAdvertisement] = useState(false);
  const [ad, setAd] = useState({});

  const showAdvertisement = () => {
    if (ad.startDate) {
      const today = new HDate();
      const todayDate = today.getDate();
      const lastShown = localStorage.getItem("ad-last-shown");
      const d = new Date();
      if (
        d.getTime() >= new Date(ad.startDate).getTime() &&
        d <= new Date(ad.endDate).setHours(23, 59, 59, 999)
      ) {
        if (lastShown && todayDate - lastShown < 3) return;
        setAdvertisement(true);
        localStorage.setItem("ad-last-shown", todayDate);
        setTimeout(() => {
          setAdvertisement(false);
        }, 15000);
      }
    }
  };

  useEffect(() => {
    if (window.location.search.includes("success")) {
      setSuccessModal(true);
      window.history.replaceState(
        null,
        "New Page Title",
        `https://thedailysicha.com${window.location.pathname}`
      );
    }
    getAd().then((res) => {
      if (res.data) {
        setAd(res.data);
      }
    });
  }, []);
  
  useEffect(() => {
    showAdvertisement();
  }, [ad]);

  useEffect(() => {
    if (isEnglish) {
      document.body.style.fontFamily = "'Times New Roman', Times, serif";
    } else {
      document.body.style.fontFamily =
        '"Alef Hebrew", "Alef", Arial, Helvetica, sans-serif';
    }
  }, [isEnglish]);

  useEffect(() => {
    setAuth(isAuthenticated());
  }, [auth]);

  return (
    <englishContext.Provider value={{ isEnglish, setIsEnglish }}>
      <alertContext.Provider value={{ isViewd, setIsViewd }}>
        <engDateDesc.Provider value={{ desc, setDesc }}>
          <Router>
            <Header auth={auth} setAuth={setAuth} />
            <RouteConfig
              auth={auth}
              setSpinner={setSpinner}
              setAuth={setAuth}
            />
            <Footer />
            <Button
              className={"donate-btn " + (mobile ? "donate-btn-mobile" : "")}
            >
              <Link to="/donate">{donateSvg}</Link>
            </Button>
            <div
              className="spinner-wrapper"
              style={spinner ? { display: "block" } : { display: "none" }}
            >
              <div className="loader-inner">
                <Loader
                  type="ThreeDots"
                  color="#4d88ff"
                  height={100}
                  width={100}
                  visible={spinner}
                  className="loader"
                />
              </div>
            </div>
            {successModal && (
              <Box
                className="modal-wrapper"
                onClick={() => setSuccessModal(false)}
                textAlign="center"
              >
                <Typography
                  onClick={(e) => e.stopPropagation()}
                  className="modal-body success-modal-body"
                  variant="h6"
                >
                  <span
                    onClick={() => setSuccessModal(false)}
                    className="close"
                  >
                    &times;
                  </span>
                  התשלום התקבל בהצלחה
                </Typography>
              </Box>
            )}
            {advertisement && (
              <Box
                className="modal-wrapper ad-modal-wrapper"
                onClick={() => setAdvertisement(false)}
                textAlign="center"
              >
                <Box className="modal-body ad-modal-body">
                  <span
                    onClick={() => setSuccessModal(false)}
                    className="close close-ad"
                  >
                    &times;
                  </span>
                  <Link to="/dedication">
                    <img
                      className="ad"
                      src={
                        process.env.REACT_APP_API_ENDPOINT +
                        "/public/assets/ad." +
                        ad.fileType
                      }
                      alt=""
                    ></img>
                  </Link>
                </Box>
              </Box>
            )}
          </Router>
        </engDateDesc.Provider>
      </alertContext.Provider>
    </englishContext.Provider>
  );
}

export default App;

	// ב"ה
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import axios from 'axios';

axios.interceptors.request.use(
  request => {
    const idToken = localStorage.getItem('id_token') || sessionStorage.getItem('id_token');
    request.headers['Authorization'] = `Bearer ${idToken}`;
    return request
  },
  error => {
    return Promise.reject(error);
  }
)

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
serviceWorkerRegistration.register();

reportWebVitals();

import React from "react";
import { HashLink } from 'react-router-hash-link';

import { Tooltip, Box, useMediaQuery } from "@material-ui/core";
import AppleIcon from "@material-ui/icons/Apple";
import PhoneInTalkIcon from "@material-ui/icons/PhoneInTalk";
import MailRoundedIcon from "@material-ui/icons/MailRounded";
import CallIcon from "@material-ui/icons/Call";
import {
  whatsappSvg,
  googleSvg2,
  applePodcast,
  googlePodcast,
  spotify,
} from "./svg";
import "./footer.css";
export default function Footer(props) {
  const matches = useMediaQuery("(min-width: 450px)");

  return (
    <>
      <footer>
        <Box className={matches ? "web-layout" : "mobile-layout"}>
          <Box display="flex" justifyContent="center" alignItems="center">
            <HashLink className="link" to="/about#phone">
              <PhoneInTalkIcon className="listen-on-phone" />
            </HashLink>
            <a
              href="https://podcasts.apple.com/us/podcast/the-daily-sicha-%D7%94%D7%A9%D7%99%D7%97%D7%94-%D7%94%D7%99%D7%95%D7%9E%D7%99%D7%AA/id1598998563"
              target="_blank"
              rel="noreferrer"
            >
              {applePodcast}
            </a>
            <a
              href="https://podcasts.google.com/feed/aHR0cHM6Ly9wb2RjYXN0LnRoZWRhaWx5c2ljaGEuY29tL2ZlZWQueG1s?ep=14"
              target="_blank"
              rel="noreferrer"
            >
              {googlePodcast}
            </a>
            <a
              href="https://open.spotify.com/show/2oCJONP63anrJVlrQCvhXB?si=S9y9SwpqSxad9ddCnD95dQ&utm_source=whatsapp&nd=1"
              target="_blank"
              rel="noreferrer"
            >
              {spotify}
            </a>
            <a
              href="https://play.google.com/store/apps/details?id=com.TheDailySicha.pwa"
              target="_blank"
              rel="noreferrer"
            >
              {googleSvg2}
            </a>
            <a
              href="https://apps.apple.com/us/app/the-daily-sicha-%D7%94%D7%A9%D7%99%D7%97%D7%94-%D7%94%D7%99%D7%95%D7%9E%D7%99%D7%AA/id1607943327?platform=iphone"
              target="_blank"
              rel="noreferrer"
            >
              {<AppleIcon className="apple-icon" />}
            </a>
          </Box>
          <Box display="flex" justifyContent="center" alignItems="center">
            <a href="tel:+13477092770" target="_blank" rel="noreferrer">
              <Tooltip
                interactive={true}
                title="347-709-2770 1+"
                placement="top"
              >
                <CallIcon className="phone-icon" />
              </Tooltip>
            </a>
            <a
              href="mailto:info@thedailysicha.com"
              target="_blank"
              rel="noreferrer"
            >
              <Tooltip
                interactive={true}
                title="info@thedailysicha.com"
                placement="top"
              >
                <MailRoundedIcon className="mail-icon" />
              </Tooltip>
            </a>
            <a
              href="https://api.whatsapp.com/send?phone=13477092770"
              target="_blank"
              rel="noreferrer"
            >
              <Tooltip
                interactive={true}
                title="347-709-2770 1+"
                placement="top"
              >
                {whatsappSvg}
              </Tooltip>
            </a>
          </Box>
        </Box>
      </footer>
    </>
  );
}
